import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { isInternalRoute } from './internalPaths';
import { useUserQuery } from './hooks/useUserQuery';

export const useRestrictedPath = () => {
  const { data: user } = useUserQuery();
  const pathname = usePathname();

  const isRestrictedPath = useMemo(() => user && isInternalRoute(pathname), [pathname, user]);

  return { isRestrictedPath };
};
