import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { Onboarding, OnboardingFlows } from './constants';

export const getOnboarding = async () => {
  const response = await cloudApiClient.get<Onboarding[]>('user/onboarding');
  return response.data;
};

export const markOnboardingComplete = async (name: OnboardingFlows) => {
  await cloudApiClient.patch(`user/onboarding/${name}`, { status: 'done' });
};
