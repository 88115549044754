import Link from 'next/link';
import { Menu } from '@mantine/core';
import { PropsWithChildren } from 'react';

export function DropdownMenuItem({
  link,
  children,
  target,
}: {
  link: string;
  target?: string;
} & PropsWithChildren) {
  return (
    <Menu.Item component={Link} target={target} href={link}>
      {children}
    </Menu.Item>
  );
}
