'use client';

import Banner, { BannerType } from '@/app/components/Banner';
import { Box, Container, Stack } from '@mantine/core';
import { useRestrictedPath } from '@/shared/useRestrictedPath';
import { useGrowthbookContext } from '@/app/contexts/GrowthbookContext';
import { usePathname } from 'next/navigation';
import { PropsWithChildren } from 'react';
import { useUserQuery } from '@/shared/hooks/useUserQuery';

function wildcardMatch(pattern: string) {
  return pattern.replace(/\*/g, '.*');
}

export default function BannerWrapper({
  excludePaths,
  disableWrapper,
}: {
  excludePaths?: string[];
  disableWrapper?: boolean;
}) {
  const growthbook = useGrowthbookContext();
  const { isRestrictedPath } = useRestrictedPath();
  const { data: user } = useUserQuery();
  const pathname = usePathname();

  const transformedExcludePaths = excludePaths ? excludePaths.map(wildcardMatch) : [];
  const shouldBeExcluded = transformedExcludePaths.some((pattern) => new RegExp(pattern).test(pathname));

  if (!user || !isRestrictedPath || shouldBeExcluded) {
    return null;
  }

  const growthbookLoading = growthbook === null;
  if (growthbookLoading) {
    return null;
  }
  const discountBannerEnabled = growthbook?.isOn('show-discount-25');

  const hasOldPlan = user.plan.details.id < 60;

  if (discountBannerEnabled && hasOldPlan) {
    return (
      <Wrapper disableWrapper={disableWrapper}>
        <Banner type={BannerType.DISCOUNT} />
      </Wrapper>
    );
  }

  const blackFridayBannerEnabled = growthbook?.isOn('blackfriday-banner');

  if (blackFridayBannerEnabled) {
    return (
      <Wrapper disableWrapper={disableWrapper}>
        <Banner type={BannerType.BLACK_FRIDAY} />
      </Wrapper>
    );
  }

  return null;
}

const Wrapper = ({ disableWrapper, children }: { disableWrapper?: boolean } & PropsWithChildren) => {
  if (disableWrapper) {
    return children;
  }

  return (
    <Box mx='sm' mt={16}>
      <Container maw='1440' px={0}>
        {children}
      </Container>
    </Box>
  );
};
