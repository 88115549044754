'use client';

import { Crisp } from 'crisp-sdk-web';
import { useEffect } from 'react';
import { useUserQuery } from '@/shared/hooks/useUserQuery';

export default function CrispChat() {
  const { data: user } = useUserQuery();
  useEffect(() => {
    Crisp.configure('922e6bf3-2bf5-48d4-ba89-5bd58ef411e1'); // Todo: make this configurable
  }, []);

  useEffect(() => {
    if (user) {
      Crisp.user.setEmail(user.email);
      Crisp.session.setData({
        user_id: user.id,
      });
    }
  }, [user]);
  return null;
}
