import { Group, Menu } from '@mantine/core';
import { IconCrown } from '@tabler/icons-react';
import Link from 'next/link';
import { ReactNode } from 'react';

interface CrownMenuItemProps {
  children: ReactNode;
  showCrown?: boolean;
  link: string;
}

export function CrownMenuItem({ children, showCrown, link }: CrownMenuItemProps) {
  return (
    <Menu.Item component={Link} href={link}>
      <Group style={{ width: '100%', justifyContent: 'space-between' }}>
        {children}
        {showCrown && <IconCrown size={16} color='black' />}
      </Group>
    </Menu.Item>
  );
}
