'use client';

import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
/*
 ***************************************
 ** STYLE OVERRIDES ARE IN global.css **
 ***************************************
 */
import * as CookieConsent from 'vanilla-cookieconsent';

interface CookieConsentContextType {
  acceptedServices: string[];
}

const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);

// Custom hook to access the context
export const useCookieConsent = (): CookieConsentContextType => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }
  return context;
};

interface CookieConsentProviderProps {
  children: ReactNode;
}

export const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({ children }) => {
  const [acceptedServices, setAcceptedServices] = useState<string[]>([]);

  useEffect(() => {
    const updateGtmConsent = () => {
      if (typeof window.gtag !== 'function') return;

      const userPreferences = CookieConsent.getUserPreferences();
      const acceptedCategories: string[] = userPreferences.acceptedCategories || [];

      const consentUpdate = {
        analytics_storage: acceptedCategories.includes('analytics') ? 'granted' : 'denied',
        ad_storage: acceptedCategories.includes('marketing') ? 'granted' : 'denied',
        personalization_storage: acceptedCategories.includes('marketing') ? 'granted' : 'denied',
        functionality_storage: 'granted',
      };

      window.gtag('consent', 'update', consentUpdate);
    };

    const updateAcceptedServices = () => {
      const userPreferences = CookieConsent.getUserPreferences();
      const acceptedServices = userPreferences.acceptedServices || [];
      const acceptedServicesList = Object.values(acceptedServices).flat();
      setAcceptedServices(acceptedServicesList);
    };

    CookieConsent.run({
      cookie: {
        domain: process.env.NEXT_PUBLIC_CONSENT_COOKIE_DOMAIN || '.rapidpipeline.com',
      },
      guiOptions: {
        consentModal: { layout: 'cloud' },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          services: {
            googleAnalytics: { label: 'Google Analytics' },
            hotjar: { label: 'Hotjar' },
          },
        },
        marketing: {
          services: {
            googleAds: { label: 'Google Ads' },
            metaAds: { label: 'Meta Ads' },
            linkedinAds: { label: 'LinkedIn Ads' },
          },
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'Privacy settings',
              description:
                'Welcome to RapidPipeline! We use cookies to enhance functionality, customize your experience, and provide relevant content and advertising. For details on our cookie use and your personal data, please refer to our <a href="https://rapidpipeline.com/en/privacy-policy/">Privacy Policy</a>.',
              acceptAllBtn: 'Accept all',
              showPreferencesBtn: 'Manage preferences',
            },
            preferencesModal: {
              title: 'Cookie Preferences',
              sections: [
                {
                  title: 'Necessary',
                  description: 'Required for the site to function.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Analytics',
                  description: 'Helps us understand site usage.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'Marketing',
                  description: 'Used for personalized ads.',
                  linkedCategory: 'marketing',
                },
              ],
              acceptAllBtn: 'Accept all',
              savePreferencesBtn: 'Save preferences',
            },
          },
        },
      },
      onChange: () => {
        updateAcceptedServices();
        updateGtmConsent();
      },
      onFirstConsent: () => {
        updateAcceptedServices();
        updateGtmConsent();
      },
    });

    const existingPreferences = CookieConsent.getUserPreferences();
    if (existingPreferences && existingPreferences.acceptedCategories) {
      updateAcceptedServices();
      updateGtmConsent();
    }
  }, []);

  return <CookieConsentContext.Provider value={{ acceptedServices }}>{children}</CookieConsentContext.Provider>;
};
