import { useUserQuery } from '@/shared/hooks/useUserQuery';
import { invalidateImpersonationQueries, removeImpersonationCookie } from '@/utils/impersonation';
import { Menu } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useImpersonationQuery } from '../../../shared/useImpersonationQuery';
import { canImpersonate } from '../../../utils/canImpersonate';
import { useGrowthbookContext } from '../../contexts/GrowthbookContext';

export const ImpersonationMenuItem = ({ open }: { open: () => void }) => {
  const queryClient = useQueryClient();
  const growthbook = useGrowthbookContext();
  const { data: user } = useUserQuery();
  const isImpersonationAvailable = canImpersonate(user);

  const { data: impersonation } = useImpersonationQuery();

  const endImpersonationAction = () => {
    removeImpersonationCookie();
    invalidateImpersonationQueries(queryClient);
  };

  const isImpersonateOn = growthbook?.isOn('impersonate-user');

  if (!isImpersonateOn || !isImpersonationAvailable) {
    return null;
  }

  return impersonation?.isImpersonating ? (
    <Menu.Item onClick={() => endImpersonationAction()}>End Impersonation</Menu.Item>
  ) : (
    <Menu.Item onClick={open}>Impersonate User</Menu.Item>
  );
};
