import { User } from '../types/user';

type ImpersonationErrorObj = {
  error: {
    message: string;
  };
};

export const isImpersonationErrorObj = (obj: User | ImpersonationErrorObj): obj is ImpersonationErrorObj => {
  if (typeof (obj as Partial<User>)?.id === 'number') {
    return false;
  }
  return (obj as ImpersonationErrorObj)?.error?.message !== undefined;
};
