import { useUserQuery } from '@/shared/hooks/useUserQuery';
import { ApiUser } from '@/types/user';
import { urlHelper } from '@/utils';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

export const usePathsGuard = () => {
  const { data: user } = useUserQuery();
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (isEmailConfirmationFlow(pathname) && user.verified) {
      return router.replace(
        shouldRedirectToCompleteRegistration(user) ? urlHelper.completeRegistration() : urlHelper.home()
      );
    }

    if (!isEmailConfirmationFlow(pathname) && !user.verified) {
      return router.replace(urlHelper.verifyAccount());
    }

    if (!isRegistrationFlow(pathname) && shouldRedirectToCompleteRegistration(user)) {
      return router.replace(urlHelper.completeRegistration());
    }
  }, [user, pathname, router]);
};

//This methods are duplicated in middleware.ts. I want to keep it that way, as I want to get rid of middleware eventually, please keep in sync.
function isEmailConfirmationFlow(pathname: string) {
  return pathname.startsWith(urlHelper.verifyEmail()) || pathname.startsWith(urlHelper.verifyAccount());
}

function isRegistrationFlow(pathname: string) {
  return isEmailConfirmationFlow(pathname) || pathname.startsWith(urlHelper.completeRegistration());
}

export function shouldRedirectToCompleteRegistration(user: ApiUser) {
  if (user.first_name) {
    return false;
  }

  if (new Date(user.created_at ?? 0) < new Date('2024-09-18')) {
    return false;
  }

  return true;
}
